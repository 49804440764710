angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.createTemplate', {
            url: '/templates/new/:type/:document',
            views: {
                "main": {
                    controller: 'CreateTemplateCtrl',
                    templateUrl: 'web/main/send/send.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                documentoParam: ($stateParams, signatureService) => signatureService.retrieveFirma($stateParams.document)
            },
            data: {
                pageTitle: 'Create Template'
            }
        });
    }).controller('CreateTemplateCtrl', function ($rootScope, $scope, $stateParams, swalService, $state, templateService, $window, $translate, fingerinkSession, documentoParam) {
        var that = this;
        that.createTemplate = true;

        that.firma = {
            id: documentoParam.data.id,
            subject: documentoParam.data.subject,
            text: documentoParam.data.text,
            pages: documentoParam.data.pages,
            type: $stateParams.type,
            sendType: 'OneDocument',
            documentFields: {
                name: 'Editar documento',
                fields: [],
                signType: 'DOCUMENT'
            },
            oneDocumentSignerFields: {
                name: 'Editar documento',
                fields: [],
                signType: 'ODS'
            },
            fields: []
        };

        that.stepActual = 0;
        that.step = 'insertfirmantes';
        that.steps = {
            Advanced: ['insertfirmantes', 'prepareDocument'],
            Fast: ['insertfirmantes'],
            Email: ['insertfirmantes'],
            SelfSigning: ['insertfirmantes', 'prepareDocument'],
            InPerson: ['insertfirmantes', 'prepareDocument']
        };

        that.siguiente = function () {
            $window.scrollTo(0, 0);
            if (that.stepActual < that.steps[that.firma.type].length - 1) {
                that.stepActual++;
                that.step = that.steps[that.firma.type][that.stepActual];
            } else {
                that.enviar();
            }
        };

        that.anterior = function () {
            if (that.stepActual > 0) {
                that.stepActual--;
                that.step = that.steps[that.firma.type][that.stepActual];
            }
        };



        that.enviar = () => {
            let fields = that.firma.fields;

            swalService.requestSwal('Guardar plantilla', null, 'warning', () => {
                fields.forEach(f => {
                    f.signer.fields = f.signer.fields || [];
                    f.signer.fields.push(f);
                    delete f.signer;
                });
                delete that.firma.fields;
                return templateService.createPlantilla(that.firma);
            }).then(function (response) {
                fingerinkSession.renoveToken();
                that.firma.fields = fields;
                that.firma.signers = that.firma.signers || [];
                [that.firma.documentFields, that.firma.oneDocumentSignerFields, ...that.firma.signers].forEach(s =>  {if(s && s.fields){ s.fields.forEach(f => f.signer = s);}});

                swal($translate.instant("¡Correcto!"), $translate.instant("Se ha guardado la plantilla correctamente"), "success");
                $state.go('main.templates');
                window.onbeforeunload = null;
                if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                    $window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Templates',
                        eventAction: 'createTemplate',
                        eventLabel: that.firma.type
                    });
                }
            }, function () {
                fingerinkSession.renoveToken();

                that.firma.fields = fields;
                that.firma.signers = that.firma.signers || [];
                [that.firma.documentFields, that.firma.oneDocumentSignerFields, ...that.firma.signers].forEach(s =>  {if(s && s.fields){ s.fields.forEach(f => f.signer = s);}});

                swal($translate.instant("Algo ha ido mal"), $translate.instant("Ha habido un error intentando crear la plantilla, por favor, inténtelo de nuevo más adelante"), "error");
            }
            );
        };

        if (!(typeof module !== 'undefined' && module.exports) ) {
            window.onbeforeunload = () => $translate.instant('¿Estás seguro que quieres salir del proceso de firma? Se pueden perder datos');
        }

    });
